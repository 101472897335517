import React from 'react'
import Layout from '../layout/subpage'

const ThankYouPage = () => (
  <Layout
    title="Thank you for getting in touch!"
    description="Thank you for contacting Web Passion. We will respond to your enquiry within 24 hours."
    keywords="Contact, Thank You"
    content="<p>Thank you for contacting Web Passion. We will respond to your enquiry within 24 hours.</p>"
  />
)

export default ThankYouPage
